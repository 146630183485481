<template>
  <div class="container">
    <loader :show="showLoader" />
    <template v-if="nativeData.agreement === false">
      <div
          class="itserve-center p-25"
          style="background-color: #1d1d1d; height: 100%"
      >
        <img
            src="@/assets/images/donetsk_energo.png"
            width="200"
            style="border-radius: 50%"
            class="company-logo m-t-10"
        />
        <div class="itserve-center mt-25 pb-25">
          <p>
            <label style="color: #cccccc">{{ $t("oferta.accept_info") }}</label>
          </p>
          <p>
            <label style="color: #cccccc">{{
                $t("oferta.accept_info1")
              }}</label>
          </p>
          <p>
            <label style="color: #cccccc">{{
                $t("oferta.accept_info2")
              }}</label>
          </p>
        </div>
        <p>
          <label
              style="color: #cccccc; text-decoration: underline"
              @click="ofertaPage"
          >{{ $t("oferta.oferta") }}</label
          >
        </p>
        <p>
          <label
              style="color: #cccccc; text-decoration: underline"
              @click="policyPage"
          >{{ $t("oferta.policy") }}</label
          >
        </p>
        <itserve-button
            :value="this.$t('buttons.confirm')"
            type="primary"
            @click="confirm"
            class="mt-15 apply-loader"
            style="color: #cccccc"
        ></itserve-button>
      </div>
    </template>
    <template v-else-if="hiddenContent === true">
      <app-content />
    </template>
    <template
        v-else-if="
        guestMode === 1 &&
        nativeData.google_id_token.length === 0 &&
        nativeData.apple_user_id.length === 0
      "
    >
      <div class="itserve-center">
        <AutorizationPage />
        <van-col class="itserve-center mb-15" span="24">
          <p>
            <label
                style="color: #cccccc; text-decoration: underline ; font-size: 24px"
                @click="guest"
            >{{ $t('buttons.skip') }}</label>
          </p>
        </van-col>
      </div>
    </template>
    <template v-else-if="authToken !== null && authToken !== undefined">
      <app-header />
      <app-content />
      <!--                  <div class="notoken-box itserve-center">-->
      <!--                    {{ nativeData }}-->
      <!--                  </div>-->
      <app-footer />
    </template>
    <template v-else>
      <div
          class="itserve-center"
          style="background-color: #1d1d1d; height: 100%"
      >
        <img
            src="@/assets/images/donetsk_energo.png"
            class="company-logo m-t-10"
            style="padding-top: 45%; border-radius: 50%"
        />
      </div>
    </template>
  </div>
</template>
<script>
import appBridge from "@/mixins/app-bridge";
import AppHeader from "@/components/template/AppHeader";
import AppFooter from "@/components/template/AppFooter";
import AppContent from "@/components/template/AppContent";
import goBack from "@/mixins/go-back";
import Loader from "@/components/content/Loader";
import ItserveButton from "@/components/forms/ItserveButton";
import { mapGetters } from "vuex";
import AutorizationPage from "@/views/autorization/AutorizationPage";
// import ItserveRoute from "@/components/content/ItserveRoute";
export default {
  name: "App",
  components: {
    AutorizationPage,
    // ItserveRoute,
    Loader,
    AppHeader,
    AppFooter,
    AppContent,
    ItserveButton,
  },
  mixins: [appBridge, goBack],
  beforeMount() {
    document.body.classList.add("app-theme-dark");
  },
  created() {
    this.nativeWaitForEvent("authToken", (authData) => {
      this.$store.dispatch("Auth/authenticate", authData);
      // if(this.$store.dispatch("Auth/authenticate", authData)) {
      //   this.nativeSendEvent("preloadInterstitial");
      // }
      this.$router.push({
        name: "Home",
      });
      if (this.nativeData.google_id_token.length > 0 || this.nativeData.google_access_token.length > 0 || this.nativeData.apple_user_id.length > 0) {
        this.nativeSendEvent("preloadInterstitial");
      }
    });
    this.nativeWaitForEvent("authTokenListen", (authData) => {
      this.$store.dispatch("Auth/authenticate", authData);
      this.$store.dispatch("Auth/newGoogleAuth");
    });

    this.nativeWaitForEvent("backButton", () => {
      if (this.$route.name === "Home") {
        this.nativeSendEvent("setPageLevel", { level: 0 });
      } else {
        this.nativeSendEvent("setPageLevel", { level: 1 });
        this.goBack();
      }
    });
    this.nativeWaitForEvent("deepLink", (deepLinkData) => {
      this.$router.push(deepLinkData);
    });
    this.nativeWaitForEvent("appCollapsed", () => {
      this.nativeSendEvent("disableFlashlight");
    });
  },
  computed: {
    ...mapGetters("Auth", ["nativeData"]),
    showLoader() {
      return this.$store.state.Api.loading;
    },
    flashlightEnabled() {
      return this.$store.state.Flashlight.flashlightEnabled;
    },
    locale() {
      return this.$store.state.Auth.locale;
    },
    appTheme() {
      return this.$store.state.Auth.appTheme;
    },
    authToken() {
      console.log("CHLENIX authToken: " + this.$store.state.Auth.authToken);
      return this.$store.state.Auth.authToken;
    },
    nativeData() {
      return this.$store.state.Auth.nativeData;
    },
    guestMode() {
      return this.$store.state.Auth.guest;
    },
    hiddenContent() {
      return this.$store.state.Auth.hidden;
    },
  },
  watch: {
    authToken() {
      this.$store.dispatch("Auth/getGuest");
      this.$store.dispatch("Auth/setupUserSettings");
    },
    locale(newLocale) {
      this.$i18n.locale = newLocale;
    },
  },
  methods: {
    async clearForGoogleAuth({ commit }) {
      await commit("Auth/SET_NATIVE_DATA", null);
    },
    confirm() {
      this.nativeSendEvent("confirmAgreenment", true);
      this.$store.state.Auth.nativeData.agreement = true;
      this.$store.commit("Settings/AGREEMENT", false);
    },
    guest() {
      this.nativeSendEvent("preloadInterstitial");
      this.$store.commit("Auth/SET_GUEST", true);
      this.$store.dispatch("Auth/storeGuest");
      this.$router.push({
        name: "Home",
      });
    },
    googleSign() {
      this.nativeSendEvent("googleSignin");
    },
    appleSign() {
      this.nativeSendEvent("appleSignin");
    },
    ofertaPage() {
      this.$store.commit("Auth/SET_HIDDEN", true);
      this.$store.state.Auth.nativeData.agreement = true;
      this.$store.commit("Settings/AGREEMENT", true);
      this.$router.push({
        name: "OfertaPage",
        params: this.$route.params,
      });
    },
    policyPage() {
      this.$store.commit("Auth/SET_HIDDEN", true);
      this.$store.state.Auth.nativeData.agreement = true;
      this.$store.commit("Settings/AGREEMENT", true);
      this.$router.push({
        name: "PolicyPage",
        params: this.$route.params,
      });
    },
  },
};
</script>
<style>
@import "assets/template.scss";
@import "assets/app.scss";
@import "assets/media.scss";
.notoken-box {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  background-color: #ffffff;
  padding-top: 25px;
}
.container_width {
  width: 337px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 15px 15px 15px 15px;
}
</style>
